<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">



      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <!-- Brand logo-->
        <b-link class="brand-logo">

        </b-link>
        <!-- /Brand logo-->
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="../assets/images/logo/alfa-tour/img-crop.png"
            alt="Login V2"
          />
          <!-- imgURL || imageBG -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
<!--          <img src="@/assets/images/logo/alfa-tour/logo.png" style="max-width: 300px; width: 100%;" >-->

          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Authorization') }}
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label-for="login-email"
              >
                <label for="login-password">{{ $t('Login') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('Password') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="user.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn"
                  @click="handlePush"
                >
                  {{ $t('Enter' ) }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { loginTheUser } from '@/api/auth'
import { setAccessToken, setUserData } from '@/libs/auth'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      user: {
        name: '',
        password: '',
        token_name:'web'
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      imageBG: "https://image.freepik.com/free-vector/background-with-sketches-travel_23-2147557362.jpg"
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handlePush(){
      loginTheUser({
        name: this.user.name,
        password: btoa(this.user.password),
        token_name:'web'
      }).then(res => {
        setAccessToken(res.data.token);
        setUserData(JSON.stringify(res.data.user));
        this.$router.push({ name: 'home' })
        this.$store.dispatch('auth/fetchMe');
      })
    },
    // validationForm() {
    //   this.$refs.loginValidation.validate().then(success => {
    //     if (success) {
    //       // eslint-disable-next-line no-unused-vars
    //       this.$store.dispatch('auth/jwtLogin', this.user).then(res => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: this.$t('SignedInSuccessfully'),
    //             icon: 'EditIcon',
    //             variant: 'success',
    //           },
    //         })
    //         this.$router.push('/')
    //       })
    //     }
    //   })
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.logo-image{
  width: 100px;
  margin-bottom: 30px;
  margin-left: 130px;
}
</style>
